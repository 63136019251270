import React from 'react'

function Footer() {
  return (
    <div className='d-flex justify-content-between align-items-center mt-4 px-2'>
        <h6 className='text-start'>Made with <i className='text-danger bi-heart-fill'></i> atayastudio</h6>
        <h6 className='text-end'>Ataya Apps V2.0</h6>
    </div>
  )
}

export default Footer