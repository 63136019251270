import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { api_user } from '../config/api'
import { useNavigate } from 'react-router-dom'

function Auth({setAktif, setStsSidebar, name, level, setName, setLevel, setLoading}) {

    const [userId, setUserId] = useState('')

    useEffect(()=>{
        setLoading(Math.random())
        setAktif("auth")
        setStsSidebar("")
    },[setAktif, setLoading, setStsSidebar])

    const navigate = useNavigate();
    useEffect(()=>{
        if(localStorage.getItem('aty_name')){
            setName(localStorage.getItem('aty_name'))
            setLevel(localStorage.getItem('aty_level'))
            navigate('/')
        }
    },[name])

    const submitLogin = async (e) => {
        e.preventDefault();
        await axios.post(api_user, {
            user: userId
        }).then((res)=>{
            console.log(res.data)
            if(res.data.sts === '200'){
                localStorage.setItem('aty_name', res.data.dat.name)
                localStorage.setItem('aty_level', res.data.dat.level)
                setName('aty_name', res.data.dat.name)
                setLevel('aty_level', res.data.dat.level)
            }
        })
    }

    return (
        <>
            <div className=' d-flex justify-content-center'>
                <div style={{position: 'absolute', top: '30%'}}>
                    <Card style={{width: '350px', height: '200px'}}>
                        <Card.Header >
                            <i className='bi-lock'></i> Ataya Authentication
                        </Card.Header>
                        <Card.Body>
                            <form onSubmit={submitLogin}>
                                <input type='text' className='form-control mt-3' value={userId} onChange={(e)=>setUserId(e.target.value)}/>
                                <Button variant='success' className='btn-block mt-3' type='submit'>Log In</Button>
                            </form>
                        </Card.Body>
                    </Card>

                </div>
            </div>
        </>
    )
}

export default Auth