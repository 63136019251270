// export const uri = "http://localhost:5000/"
export const uri = "https://backend2.ataya.studio/"

export const api_get_products       = uri + "products"
export const api_get_product_by_sku = uri + "products/"
export const api_create_product     = uri + "products/create"
export const api_update_product     = uri + "products/update/"
export const api_delete_product     = uri + "products/delete/"

export const api_get_logistics      = uri + "logistics"
export const api_get_logistic_by_id = uri + "logistics/"
export const api_next_logistics     = uri + "logistics/next/"
export const api_create_logistics   = uri + "logistics/create"
export const api_update_logistics   = uri + "logistics/update/"
export const api_delete_logistics   = uri + "logistics/delete/"

export const api_get_orders         = uri + "orders"
export const api_get_order_shopee   = uri + "orders/get_shopee_order/"
export const api_update_note        = uri + "orders/update_note"
export const api_design_upload      = uri + "orders/design_upload"
export const api_update_shipping    = uri + "orders/update_shipping"
export const api_update_progress    = uri + "orders/update_progress"
export const api_update_due_date    = uri + "orders/update_due_date"
export const api_create_manual_order= uri + "orders/create_manual_order"
export const api_delete_order_by_id = uri + "orders/delete_order_by_id/"
export const api_delete_order_by_no = uri + "orders/delete_order_by_no/"

export const api_get_ng             = uri + "ng"
export const api_get_ng_by_id       = uri + "ng/"
export const api_create_ng          = uri + "ng/create"
export const api_update_ng          = uri + "ng/update/"
export const api_delete_ng          = uri + "ng/delete/"

export const api_user               = uri + "user"


