import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/app.css"
import "./assets/css/bi.css"
import "./assets/plugins/fontawesome-free/css/all.css"
import Footer from "./component/Footer";
import Header from "./component/Header";
import Sidebar from "./component/Sidebar";
import { api_get_logistics, api_get_ng, api_get_orders, api_get_products } from "./config/api";
import Dashboard from "./pages/Dashboard";
import Inventory from "./pages/Inventory";
import NotGood from "./pages/NotGood";
import Production from "./pages/Production";
import InvoiceQuotation from "./pages/InvoiceQuotation";
import Analysis from "./pages/Analysis";
import Auth from "./pages/Auth";
import Absen from "./pages/Absen";

function App() {
  
  //Side Bar Control
  const [stsSidebar, setStsSidebar] = useState("")
  const [aktif, setAktif]           = useState("")
  const [loading, setLoading]       = useState(true);
  const [ng, setNg]                 = useState([]);
  const [orders, setOrders]         = useState([]);
  const [products, setProducts]     = useState([]);
  const [logistics, setLogistics]   = useState([]);
  const [name, setName] = useState('Name')
  const [level, setLevel] = useState('Level')
  useEffect(() => {
    const showProduct = async () =>{
      await axios.get(api_get_products)
      .then(response=>{
      console.log(api_get_products)
        setProducts(response.data.dat);
      })
    }
    showProduct();

    const showOrders = async () =>{
      await axios.get(api_get_orders)
      .then(response=>{
        console.log(api_get_orders);
        setOrders(response.data.dat);
      })
    }
    showOrders();

    const showLogistic = async () =>{
      await axios.get(api_get_logistics)
      .then(response=>{
        console.log(api_get_logistics)
        setLogistics(response.data.dat);
      })
    }
    showLogistic();

    const showNg = async () =>{
      await axios.get(api_get_ng)
      .then(response=>{
        console.log(api_get_ng)
        setNg(response.data.dat);
        setLoading(false)
      })
    }
    showNg();
  }, [loading])

  return (
    <>
      <BrowserRouter>
        <Sidebar stsSidebar={stsSidebar} setStsSidebar={setStsSidebar} aktif={aktif} name={name} level={level}/>
          <Header stsSidebar={stsSidebar} setStsSidebar={setStsSidebar} name={name} level={level} setName={setName} setLevel={setLevel}/>
          <div id="core">
            <Routes>
              {/* <Route path="*" element={<NotFound />} /> */}
              <Route path="/auth"               element={<Auth              setAktif={setAktif} setStsSidebar={setStsSidebar} setLoading={setLoading} name={name} level={level} setName={setName} setLevel={setLevel}/>} />
              <Route path="/"                   element={<Dashboard         setAktif={setAktif} setStsSidebar={setStsSidebar} setLoading={setLoading} name={name} level={level} products={products} orders={orders} logistics={logistics}/>} />
              <Route path="/inventory"          element={<Inventory         setAktif={setAktif} setStsSidebar={setStsSidebar} setLoading={setLoading} name={name} level={level} products={products} orders={orders} logistics={logistics} ng={ng}/>} />
              <Route path="/production"         element={<Production        setAktif={setAktif} setStsSidebar={setStsSidebar} setLoading={setLoading} name={name} level={level} products={products} orders={orders}/>} />
              <Route path="/analysis"           element={<Analysis          setAktif={setAktif} setStsSidebar={setStsSidebar} setLoading={setLoading} name={name} level={level} products={products} orders={orders}/>} />
              <Route path="/not_good"           element={<NotGood           setAktif={setAktif} setStsSidebar={setStsSidebar} setLoading={setLoading} name={name} level={level} products={products} ng={ng}/>} />
              <Route path="/invoice_quotation"  element={<InvoiceQuotation  setAktif={setAktif} setStsSidebar={setStsSidebar} setLoading={setLoading} name={name} level={level} products={products}/>} />
              <Route path="/absen"              element={<Absen             setAktif={setAktif} setStsSidebar={setStsSidebar} setLoading={setLoading} name={name} level={level} />} />
            </Routes>
          </div>
        <Footer />
      </ BrowserRouter>
    </>
  );
}

export default App;
