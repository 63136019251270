import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/img/Logopendek.png'

function Sidebar({stsSidebar, setStsSidebar, aktif, name, level}) {

    //Side Bar Control
    const chgStsSidebar = () => {
        if(stsSidebar === ""){setStsSidebar("active")}else{setStsSidebar("")}
    }
      
    // useEffect(() => {
    //     const getDimension = () => {
    //         if(window.innerWidth > 1199){
    //             setStsSidebar("active")
    //         }else{
    //             setStsSidebar("")
    //         }
    //     }

    //     window.addEventListener('resize', getDimension);

    //     return(() => {
    //         window.removeEventListener('resize', getDimension);
    //     })
    // }, [setStsSidebar])
    // //End of Sidebar Control


    return (
        <>
            <div id="sidebar" className={stsSidebar}>
                <div className="position-relative">
                    <div className="position-absolute top-50 start-93 translate-middle-y">
                        <h3><b><i className='bi bi-x' role="button" onClick={chgStsSidebar}></i></b></h3>
                    </div>
                </div>
                <div className='sidebar-header my-4 text-center'>
                    <img src={logo} alt="logo" width="50%" />
                </div>
                <hr className='mb-3' />
                <ul className="nav nav-pills flex-column">
                    <li className="nav-item">
                        <Link to="/" role="button" className={aktif && aktif === "dashboard" ? "nav-link active p-2" : "nav-link p-2"}>
                            <i className="bi bi-grid-fill mx-2 p-0"></i> Dashboard
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/inventory"  role="button" className={aktif && aktif === "inventory" ? "nav-link active p-2" : "nav-link p-2"}>
                            <i className="bi-box-seam mx-2"></i> Inventory
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/production"  role="button" className={aktif && aktif === "production" ? "nav-link active p-2" : "nav-link p-2"}>
                            <i className="bi-tools mx-2"></i> Production
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/not_good"  role="button" className={aktif && aktif === "not_good" ? "nav-link active p-2" : "nav-link p-2"}>
                            <i className="bi-x-lg mx-2"></i> Not Good
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/invoice_quotation"  role="button" className={aktif && aktif === "invoice" ? "nav-link active p-2" : "nav-link p-2"}>
                            <i className="bi-file-earmark-text mx-2"></i> Invoice & Quotation
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/analysis"  role="button" className={aktif && aktif === "analysis" ? "nav-link active p-2" : "nav-link p-2"}>
                            <i className="bi-bar-chart-fill mx-2"></i> Analysis
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/absen"  role="button" className={aktif && aktif === "absen" ? "nav-link active p-2" : "nav-link p-2"}>
                            <i className="bi-calendar mx-2"></i> Absen
                        </Link>
                    </li>
                    <li className="nav-item">
                        <div role="button" className="nav-link p-2" onClick={()=>{window.location = "https://ataya.studio/admin_old/file_manager"}}>
                            <i className="bi-folder mx-2"></i> File Manager
                        </div>
                    </li>
                    {
                        level === "Super Admin" ? 
                            <a href='https://api-shopee.ataya.studio/token/get_token.php' target='_blank' className="nav-item">
                                <div role="button" className="nav-link p-2">
                                    <i className="bi-coin mx-2"></i> Get Access Token
                                </div>
                            </a>
                            : ""
                    }
                </ul>
            </div>
        </>
    )
}

export default Sidebar