import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { api_delete_logistics, api_get_logistic_by_id, api_next_logistics, api_update_logistics } from "../config/api";

function InventoryOrder({setLoading, products, logistics, level}) {
    const [showActive, setShowActive] = useState("Plan")
    const [plan, setPlan] = useState([])
    const [order, setOrder] = useState([])
    const [finish, setFinish] = useState([])
     
    const [showing, setShowing] = useState([])

    const show = (status) => {
        setShowing(logistics.filter(logistic => {
            return logistic.status === status;
        }))
    }

    useEffect(() => {
        setPlan(logistics.filter(logistic => {
            return logistic.status === "Plan";
        }))
        
        setOrder(logistics.filter(logistic => {
            return logistic.status === "Order";
        }))

        setFinish(logistics.filter(logistic => {
            return logistic.status === "Finish";
        }))
        
        show(showActive)
    },[logistics])

    const handleShow = (status) =>{
        setShowActive(status)
        show(status)
    }
    
    const [modalEdit, setModalEdit] = useState(false);
    const closeModalEdit = () => setModalEdit(false);
    const showModalEdit = () => setModalEdit(true);

    const [idEdit, setIdEdit] = useState("")
    const [skuEdit, setSkuEdit] = useState("")
    const [qtyEdit, setQtyEdit] = useState("")
    const [statusEdit, setStatusEdit] = useState("")
    
    const handleModalEdit = (id, sku, qty, status) =>{
        setIdEdit(id)
        setSkuEdit(sku)
        setQtyEdit(qty)
        setStatusEdit(status)
        showModalEdit()
    }

    const updateOrder = async (e) =>{
        e.preventDefault();
        closeModalEdit();

        await axios.post(api_update_logistics + idEdit, {
            qty     : qtyEdit,
            status  : statusEdit
        })
        .then(res => {
            console.log(api_update_logistics + idEdit)
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            
            setLoading(Math.random())
        })
    }

    const deleteLogistic = async (id) =>{
        await axios.delete(api_delete_logistics + id)
        .then(res=>{
            console.log(api_delete_logistics + id)
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            closeModalEdit();
            setLoading(Math.random())
        })
    } 

    const handleNext = async (id, status) => {
        let sendData

        if(status === "Plan"){
            sendData = {
                status      : "Order",
                user        : "SYSTEM"
            }
        }else if(status === "Order"){
            sendData = {
                status      : "Finish",
                user        : "SYSTEM"
            }
        }

        await axios.post(api_next_logistics + id, sendData)
        .then(res => {
            console.log(api_next_logistics + id)
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            
            setLoading(Math.random())
        })
        handleShow(status)
        
    }

    const [infoLogistic, setInfoLogistic] = useState("")
    const [modalInfo, setModalInfo] = useState("")
    const showInfo = async (id) =>{
        await axios.get(api_get_logistic_by_id + id)
        .then(response=>{
            console.log(api_get_logistic_by_id + id)
            setModalInfo(true)
            setInfoLogistic(response.data.dat)
        })
    }


    return (
        <>  
            <Row className="mb-2 px-3">
                <div className="col-4 p-1">
                    <button 
                        className=
                            {showActive && showActive === "Plan" ? 
                                'btn btn-sm btn-block btn-outline-info btn-pills active text-white' :
                                'btn btn-sm btn-block btn-outline-info btn-pills'
                            }
                        onClick={()=>handleShow("Plan")}
                    >
                        Plan <span className="badge bg-info text-white">{plan.length}</span>
                    </button>
                </div>
                <div className="col-4 p-1">
                    <button 
                        className=
                            {showActive && showActive === "Order" ? 
                                'btn btn-sm btn-block btn-outline-info btn-pills active text-white' :
                                'btn btn-sm btn-block btn-outline-info btn-pills'
                            }
                        onClick={()=>handleShow("Order")}
                    >
                        Order <span className="badge bg-info text-white">{order.length}</span>
                    </button>
                </div>
                <div className="col-4 p-1">
                    <button 
                        className=
                            {showActive && showActive === "Finish" ? 
                                'btn btn-sm btn-block btn-outline-info btn-pills active text-white' :
                                'btn btn-sm btn-block btn-outline-info btn-pills'
                            }
                        onClick={()=>handleShow("Finish")}
                    >
                        Finish <span className="badge bg-info text-white">{finish.length}</span>
                    </button>
                </div>
            </Row>
            {showing.length > 0 ? 
                <Row className="px-1">
                    <table className="table table-bordered t9">
                        <thead className="text-center">
                            <tr>
                                <th width="40%">SKU</th>
                                <th width="30%">Qty</th>
                                <th width="30%">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>                        
                            {showing && showing.map((logistic, index) => {
                                // const plantime = new Date(logistic.plan_time * 1000).toLocaleDateString('en-US');
                                const product = products.filter(product => {
                                    return product.sku === logistic.sku
                                });
                                const xx = product[0]
                                return(
                                    <tr className="text-center" key={logistic.id}>
                                        <td className="text-start">{xx?.name}</td>
                                        <td >{logistic.qty}</td> 
                                        <td className="p-1">
                                            <div>
                                                {
                                                    level && level === 'Super Admin' ? 
                                                    <button className='btn btn-sm btn-warning me-1' onClick={()=> handleModalEdit(logistic.id, logistic.sku, logistic.qty, logistic.status)}><i className='bi-pen'></i></button>
                                                    : ""
                                                }
                                                {logistic.status=== "Plan" ? 
                                                    <button className='btn btn-sm btn-info' onClick={()=> handleNext(logistic.id, logistic.status)}><i className='bi-truck'></i></button>
                                                    : ""
                                                }
                                                {logistic.status=== "Order" ? 
                                                    <button className='btn btn-sm btn-info' onClick={()=> handleNext(logistic.id, logistic.status)}><i className='bi-check2-square'></i></button>
                                                    : ""
                                                }
                                                {logistic.status=== "Finish" ? 
                                                    <button className='btn btn-sm btn-info me-1' onClick={()=> showInfo(logistic.id)}><i className='bi-info-lg'></i></button>
                                                : ""
                                                }
                                                
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Row>
            : 
                "" // <div className="text-center mt-4 text-danger">Tidak ada order dengan status {showActive}</div>
            }

            <Modal show={modalEdit} onHide={closeModalEdit} centered>
                <Modal.Header className="h5 py-2 bg-info" closeButton>
                <Modal.Title>Edit Order</Modal.Title>
                </Modal.Header>
                <Modal.Body className="t9">
                    <Form onSubmit={updateOrder}> 
                        <Form.Group>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Id</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm" type="text"  value={idEdit} disabled />
                                </Col>
                            </Row><Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>SKU</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm" type="text"  value={skuEdit} disabled />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Qty</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm"  type="number"  value={qtyEdit} onChange={(e) => setQtyEdit(e.target.value)}  placeholder="Masukkan Edit Qty" required />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Status</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Select value={statusEdit} onChange={(e) => setStatusEdit(e.target.value)}>
                                        <option value="Plan">Plan</option>
                                        <option value="Order">Order</option>
                                        <option value="Finish">Finish</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Form.Group>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <Button variant="danger" onClick={()=>deleteLogistic(idEdit)}>
                                <i className="bi-trash me-2"></i>Hapus
                            </Button>
                            <Button variant="info" type="submit">
                                Save Changes
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={modalInfo} onHide={()=>setModalInfo(false)} centered>
                <Modal.Header className="h5 py-2 bg-info" closeButton>
                <Modal.Title>Info Logistic</Modal.Title>
                </Modal.Header>
                <Modal.Body className="t9">
                    <table width="100%">
                        <tbody>
                        <tr>
                            <td width="40%">
                                id
                            </td>
                            <td width="60%">
                                : {infoLogistic.id}
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                SKU
                            </td>
                            <td width="60%">
                                : {infoLogistic.sku}
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                Qty
                            </td>
                            <td width="60%">
                                : {infoLogistic.qty}
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                Status
                            </td>
                            <td width="60%">
                                : {infoLogistic.status}
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                Plan Time
                            </td>
                            <td width="60%">
                                {": " +new Date(infoLogistic.plan_time * 1).toLocaleDateString('id-ID')} - 
                                {" " +new Date(infoLogistic.plan_time * 1).getHours()}
                                {":" +new Date(infoLogistic.plan_time * 1).getMinutes()}
                                {":" +new Date(infoLogistic.plan_time * 1).getSeconds()}
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                Planed By
                            </td>
                            <td width="60%">
                                : {infoLogistic.planed_by}
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                Order Time
                            </td>
                            <td width="60%">
                                {": " +new Date(infoLogistic.order_time * 1).toLocaleDateString('id-ID')} - 
                                {" " +new Date(infoLogistic.order_time * 1).getHours()}
                                {":" +new Date(infoLogistic.order_time * 1).getMinutes()}
                                {":" +new Date(infoLogistic.order_time * 1).getSeconds()}
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                Ordered By
                            </td>
                            <td width="60%">
                                : {infoLogistic.ordered_by}
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                Finish Time
                            </td>
                            <td width="60%">
                                {": " +new Date(infoLogistic.finish_time * 1).toLocaleDateString('id-ID')} - 
                                {" " +new Date(infoLogistic.finish_time * 1).getHours()}
                                {":" +new Date(infoLogistic.finish_time * 1).getMinutes()}
                                {":" +new Date(infoLogistic.finish_time * 1).getSeconds()}
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">
                                Finished By
                            </td>
                            <td width="60%">
                                : {infoLogistic.finished_by}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </>
        )
    }
    
export default InventoryOrder