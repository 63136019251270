import React, { useEffect, useState } from 'react'
import logo from "../assets/img/logo2.png"
import lunasimg from "../assets/img/lunas.png"
import { NumericFormat } from 'react-number-format';
import "./invoice.css"
import { useNavigate } from 'react-router-dom';

function InvoiceQuotation({setAktif, setStsSidebar, name, level}) {
    const navigate = useNavigate()
    useEffect(()=>{
        if(name === 'Name') navigate('/auth')
        setAktif("invoice")
        setStsSidebar("")
    },[setAktif, setStsSidebar])

    const [type, setType]           = useState("Invoice")
    const [customer, setCustomer]   = useState("")
    const [company, setCompany]     = useState("")
    const [address, setAddress]     = useState("")
    const [dateIn, setDateIn]       = useState("")
    const [ongkir, setOngkir]       = useState("")
    const [discount, setDiscount]   = useState("")
    const [ppn, setPpn]             = useState(false)
    const [lunas, setLunas]         = useState(false)
    const [state1, setState1]       = useState(false)
    const [item1, setItem1]         = useState("")
    const [price1, setPrice1]       = useState("")
    const [qty1, setQty1]           = useState("")
    const [state2, setState2]       = useState(false)
    const [item2, setItem2]         = useState("")
    const [price2, setPrice2]       = useState("")
    const [qty2, setQty2]           = useState("")
    const [state3, setState3]       = useState(false)
    const [item3, setItem3]         = useState("")
    const [price3, setPrice3]       = useState("")
    const [qty3, setQty3]           = useState("")
    const [state4, setState4]       = useState(false)
    const [item4, setItem4]         = useState("")
    const [price4, setPrice4]       = useState("")
    const [qty4, setQty4]           = useState("")
    const [state5, setState5]       = useState(false)
    const [item5, setItem5]         = useState("")
    const [price5, setPrice5]       = useState("")
    const [qty5, setQty5]           = useState("")
    const [state6, setState6]       = useState(false)
    const [item6, setItem6]         = useState("")
    const [price6, setPrice6]       = useState("")
    const [qty6, setQty6]           = useState("")

    const toggle1 = () => {
        setState1(!state1)
        setQty1("")
    }
    const toggle2 = () => {
        setState2(!state2)
        setQty2("")
    }
    const toggle3 = () => {
        setState3(!state3)
        setQty3("")
    }
    const toggle4 = () => {
        setState4(!state4)
        setQty4("")
    }
    const toggle5 = () => {
        setState5(!state5)
        setQty5("")
    }
    const toggle6 = () => {
        setState6(!state6)
        setQty6("")
    }
    const ppnChange = () => {
        setPpn(!ppn)
    }
    const lunasChange = () => {
        setLunas(!lunas)
    }

    

    let originalContents
    let printContents
    const Print = () =>{     
        //console.log('print');  
        originalContents = document.body.innerHTML;
        printContents = document.getElementById('printablediv').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        setTimeout(()=>{document.body.innerHTML = originalContents}, 2000)
    }
    return (
        <div className="content-wrapper inv">
            <div className="row p-2">
                <div className="col-lg-6">
                    <div className="card p-3 inv" id='printablediv' style={{backgroundColor: "#fff",zIndex: 0}}>
                        <div className="inv">
                            <div className="d-flex justify-content-between">
                                <img src={logo} alt="logo" height="45px" className='m-4'/>
                                <div className='text-end'>
                                    <h3 className='mt-4 mx-3 mb-0 fw-bold text-info'>{type}</h3>
                                    <p className='mx-3 fw-bold p-t7 '>{new Date(dateIn).toLocaleDateString("id-ID", {day: "2-digit", month: "short", year:"numeric"})}</p>
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-6 p-t8">
                                    <p className='mb-2 fw-bold p-t9'>Atayastudio</p>
                                    <p className='mb-1'>Cluster Cherryville Grandwisata Blok hc 3 no 8</p>
                                    <p className='mb-1'>Lambangjaya, Cibitung, Kab. Bekasi, Jawa Barat</p>
                                    <p className='mb-1'>hi@ataya.studio</p>
                                </div>
                                <div className="col-6 text-end p-t8">
                                    <p className='mb-2 fw-bold p-t9'>{type === "Invoice" ? "Invoiced To" : "Quotation For"}</p>
                                    <p className='mb-1'>{customer}</p>
                                    <p className='mb-1'>{company}</p>
                                    <p className='mb-1'>{address}</p>
                                </div>
                                <table className='table table-bordered mt-5 p-t8 border-dark' style={{zIndex: -1}}>
                                    <thead className=' bg-secondary' style={{WebkitPrintColorAdjust: 'exact'}}>
                                        <tr className='text-center fw-bold text-white'>
                                            <td width="46%">Item</td>
                                            <td width="18%">Price</td>
                                            <td width="18%">Qty</td>
                                            <td width="18%">Total</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state1 === true ? 
                                            <tr className='text-end'>
                                                <td className='text-start'>{item1}</td>
                                                <td><NumericFormat value={price1} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={qty1} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={price1 * qty1} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                            </tr>
                                        : ""}
                                        {state2 === true ? 
                                            <tr className='text-end'>
                                                <td className='text-start'>{item2}</td>
                                                <td><NumericFormat value={price2} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={qty2} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={price2 * qty2} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                            </tr>
                                        : ""}
                                        {state3 === true ? 
                                            <tr className='text-end'>
                                                <td className='text-start'>{item3}</td>
                                                <td><NumericFormat value={price3} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={qty3} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={price3 * qty3} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                            </tr>
                                        : ""}
                                        {state4 === true ? 
                                            <tr className='text-end'>
                                                <td className='text-start'>{item4}</td>
                                                <td><NumericFormat value={price4} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={qty4} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={price4 * qty4} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                            </tr>
                                        : ""}
                                        {state5 === true ? 
                                            <tr className='text-end'>
                                                <td className='text-start'>{item5}</td>
                                                <td><NumericFormat value={price5} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={qty5} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={price5 * qty5} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                            </tr>
                                        : ""}
                                        {state6 === true ? 
                                            <tr className='text-end'>
                                                <td className='text-start'>{item6}</td>
                                                <td><NumericFormat value={price6} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={qty6} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                                <td><NumericFormat value={price6 * qty6} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} /></td>
                                            </tr>
                                        : ""}
                                        <tr>
                                            <td colSpan={2} rowSpan={ppn === true ? 5 : 4} className='p-3  align-middle text-center'>
                                                {lunas ?
                                                <   img src={lunasimg} alt='bg-lunas' className='bg-lunas'/>
                                                : ""}
                                            </td>
                                            <td><b>Sub Total</b></td>
                                            <td className='text-end'>
                                                <NumericFormat value={price1 * qty1 + price2 * qty2 + price3 * qty3 + price4 * qty4 + price5 * qty5 + price6 * qty6} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Delivery Fee</b></td>
                                            <td className='text-end'>
                                                <NumericFormat value={ongkir} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Discount</b></td>
                                            <td className='text-end'>
                                                <NumericFormat value={discount} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} />
                                            </td>
                                        </tr>
                                        {ppn === true ? 
                                            <>
                                                <tr>
                                                    <td><b>PPN 11%</b></td>
                                                    <td className='text-end'>
                                                        <NumericFormat value={(price1 * qty1 + price2 * qty2 + price3 * qty3 + price4 * qty4 + price5 * qty5 + price6 * qty6 - discount + (1 * ongkir)) * 0.11} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} decimalScale={0}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td className='text-end'>
                                                        <NumericFormat value={price1 * qty1 + price2 * qty2 + price3 * qty3 + price4 * qty4 + price5 * qty5 + price6 * qty6 - discount + (1 * ongkir) + ((price1 * qty1 + price2 * qty2 + price3 * qty3 + price4 * qty4 + price5 * qty5 + price6 * qty6 - discount + (1 * ongkir)) * 0.11)} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''}  decimalScale={0}/>
                                                    </td>
                                                </tr>
                                            </>
                                        :
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td className='text-end'>
                                                    <NumericFormat value={price1 * qty1 + price2 * qty2 + price3 * qty3 + price4 * qty4 - discount + (1 * ongkir)} displayType={'text'} thousandSeparator="." decimalSeparator=',' prefix={''} decimalScale={0}/>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <p className='mb-1 p-t9'>Term of Payment :</p>
                                <p className='mb-4 p-t9'>1. Pembayaran down payment minimal 50%</p>
                                <p className='mb-1 p-t9 fw-bold'>Bank BCA : 5465115707</p>
                                <p className='mb-2 p-t9 fw-bold'>a.n Muchromin</p>
                                <p className='mb-5 mt-2 text-end p-t9'>Hormat Kami,</p>
                                <p className='mt-2 mb-0 text-end p-t9'>Ilham</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mt-4 px-3">
                    <div className="row mb-2">
                        <div className="col-6 text-center px-1">
                            Type
                        </div>
                        <div className="col-6 text-center px-1">
                            Date
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-6 px-1">
                            <select className='form-control' value={type} onChange={(e)=>{setType(e.target.value); document.title = e.target.value + " - " + customer;}}>
                                <option>Invoice</option>
                                <option>Quotation</option>
                            </select>
                        </div>
                        <div className="col-6 px-1">
                            <input type="date" className='form-control' value={dateIn} onChange={(e)=>setDateIn(e.target.value)}/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-6 text-center px-1">
                            Customer
                        </div>
                        <div className="col-6 text-center px-1">
                            Company
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-6 px-1">
                            <input type="text" className='form-control' value={customer} onChange={(e)=>{setCustomer(e.target.value); document.title = type + " - " + e.target.value;}}/>
                        </div>
                        <div className="col-6 px-1">
                            <input type="text" className='form-control' value={company} onChange={(e)=>setCompany(e.target.value)}/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 text-center px-1">
                            Address
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 px-1">
                            <input type="text" className='form-control' value={address} onChange={(e)=>setAddress(e.target.value)}/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-1 text-center align-middle">
                        </div>
                        <div className="col-6 text-center px-1">
                            Item
                        </div>
                        <div className="col-3 text-center px-1">
                            Harga
                        </div>
                        <div className="col-2 text-center px-1">
                            Qty
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-1 text-center align-middle">
                            <i className={state1 === false ? "bi-toggle-off t15" : "bi-toggle-on t15 text-success"} role='button' onClick={toggle1}></i>
                        </div>
                        <div className="col-6 px-1">
                            <input type="text" className='form-control' value={item1} onChange={(e)=>setItem1(e.target.value)} disabled={state1 === false ? true : false}/>
                        </div>
                        <div className="col-3 px-1">
                            <input type="number" className='form-control' value={price1} onChange={(e)=>setPrice1(e.target.value)} disabled={state1 === false ? true : false}/>
                        </div>
                        <div className="col-2 px-1">
                            <input type="number" className='form-control' value={qty1} onChange={(e)=>setQty1(e.target.value)} disabled={state1 === false ? true : false}/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-1 text-center align-middle">
                            <i className={state2 === false ? "bi-toggle-off t15" : "bi-toggle-on t15 text-success"} role='button' onClick={toggle2}></i>
                        </div>
                        <div className="col-6 px-1">
                            <input type="text" className='form-control' value={item2} onChange={(e)=>setItem2(e.target.value)} disabled={state2 === false ? true : false}/>
                        </div>
                        <div className="col-3 px-1">
                            <input type="number" className='form-control' value={price2} onChange={(e)=>setPrice2(e.target.value)} disabled={state2 === false ? true : false}/>
                        </div>
                        <div className="col-2 px-1">
                            <input type="number" className='form-control' value={qty2} onChange={(e)=>setQty2(e.target.value)} disabled={state2 === false ? true : false}/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-1 text-center align-middle">
                            <i className={state3 === false ? "bi-toggle-off t15" : "bi-toggle-on t15 text-success"} role='button' onClick={toggle3}></i>
                        </div>
                        <div className="col-6 px-1">
                            <input type="text" className='form-control' value={item3} onChange={(e)=>setItem3(e.target.value)} disabled={state3 === false ? true : false}/>
                        </div>
                        <div className="col-3 px-1">
                            <input type="number" className='form-control' value={price3} onChange={(e)=>setPrice3(e.target.value)} disabled={state3 === false ? true : false}/>
                        </div>
                        <div className="col-2 px-1">
                            <input type="number" className='form-control' value={qty3} onChange={(e)=>setQty3(e.target.value)} disabled={state3 === false ? true : false}/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-1 text-center align-middle">
                            <i className={state4 === false ? "bi-toggle-off t15" : "bi-toggle-on t15 text-success"} role='button' onClick={toggle4}></i>
                        </div>
                        <div className="col-6 px-1">
                            <input type="text" className='form-control' value={item4} onChange={(e)=>setItem4(e.target.value)} disabled={state4 === false ? true : false}/>
                        </div>
                        <div className="col-3 px-1">
                            <input type="number" className='form-control' value={price4} onChange={(e)=>setPrice4(e.target.value)} disabled={state4 === false ? true : false}/>
                        </div>
                        <div className="col-2 px-1">
                            <input type="number" className='form-control' value={qty4} onChange={(e)=>setQty4(e.target.value)} disabled={state4 === false ? true : false}/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-1 text-center align-middle">
                            <i className={state5 === false ? "bi-toggle-off t15" : "bi-toggle-on t15 text-success"} role='button' onClick={toggle5}></i>
                        </div>
                        <div className="col-6 px-1">
                            <input type="text" className='form-control' value={item5} onChange={(e)=>setItem5(e.target.value)} disabled={state5 === false ? true : false}/>
                        </div>
                        <div className="col-3 px-1">
                            <input type="number" className='form-control' value={price5} onChange={(e)=>setPrice5(e.target.value)} disabled={state5 === false ? true : false}/>
                        </div>
                        <div className="col-2 px-1">
                            <input type="number" className='form-control' value={qty5} onChange={(e)=>setQty5(e.target.value)} disabled={state5 === false ? true : false}/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-1 text-center align-middle">
                            <i className={state6 === false ? "bi-toggle-off t15" : "bi-toggle-on t15 text-success"} role='button' onClick={toggle6}></i>
                        </div>
                        <div className="col-6 px-1">
                            <input type="text" className='form-control' value={item6} onChange={(e)=>setItem6(e.target.value)} disabled={state6 === false ? true : false}/>
                        </div>
                        <div className="col-3 px-1">
                            <input type="number" className='form-control' value={price6} onChange={(e)=>setPrice6(e.target.value)} disabled={state6 === false ? true : false}/>
                        </div>
                        <div className="col-2 px-1">
                            <input type="number" className='form-control' value={qty6} onChange={(e)=>setQty6(e.target.value)} disabled={state6 === false ? true : false}/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-4 text-center px-1">
                            Delivery Fee
                        </div>
                        <div className="col-4 text-center px-1">
                            Discount
                        </div>
                        <div className="col-2 text-center px-1">
                            PPN
                        </div>
                        <div className="col-2 text-center px-1">
                            Lunas
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-4 px-1">
                            <input type="number" className='form-control' value={ongkir} onChange={(e)=>setOngkir(e.target.value)}/>
                        </div>
                        <div className="col-4 px-1">
                            <input type="number" className='form-control' value={discount} onChange={(e)=>setDiscount(e.target.value)}/>
                        </div>
                        <div className="col-2 text-center align-middle">
                            <i className={ppn === false ? "bi-toggle-off t15" : "bi-toggle-on t15 text-success"} role='button' onClick={ppnChange}></i>
                        </div>
                        <div className="col-2 text-center align-middle">
                            <i className={lunas === false ? "bi-toggle-off t15" : "bi-toggle-on t15 text-success"} role='button' onClick={lunasChange}></i>
                        </div>
                    </div>
                    <button className='btn btn-block btn-primary mt-4' onClick={Print}><i className=' bi-printer'></i> Print</button>
                </div>
            </div>
        </div>
    )
}

export default InvoiceQuotation