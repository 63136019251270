import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


function Analysis({setAktif,setStsSidebar, setLoading, name, level, products, orders}) {
    const navigate = useNavigate()
    useEffect(()=>{
        if(name === 'Name') navigate('/auth')
        setAktif("analysis")
        setStsSidebar("")
    },[setAktif,setStsSidebar])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
        },
    };
    
    const [datas, setDatas] = useState([])
    const [year, setYear] = useState(2023)
    const period = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    
    let isi = []
    period?.map((period, index) => {
        const totOrd = orders.filter(order => {
            return order.order_periode === `${period}-${year}` && order.sku.substring(0,2) !== 'aa' && order.sku.substring(0,2) !== 'AA' && order.sku.substring(0,2) !== 'EX'
        });

        let qty_order;
        if(year === 2023 && period === '01'){
            qty_order = 2060;
        }else if(year === 2023 && period === '02'){
            qty_order = 1877;
        }else if(year === 2023 && period === '03'){
            qty_order = 1565;
        }else{
            qty_order   = totOrd.reduce(function(prev, current) {
                return prev + +current.qty
            }, 0);
        }

        isi.push(qty_order)
    })



    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const data = {
        labels,
        datasets: [
            {
                label: 'Qty Order',
                data: isi,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    
    return (
        <div className='content-wrapper px-3'>
            <div className="d-flex justify-content-between">
                <h5 className='fw-bold mb-3'>Analysis</h5>
                {/* <select>
                    <options>2023</options>
                    <options>2024</options>
                    <options>2025</options>
                </select> */}
            </div>
            <Card className='p-4 d-lg-none'>
                <h4 className='text-center fw-bold'>Order Qty Atayastudio</h4>
                <select className='form-control text-end my-3' onChange={(e)=>setYear(e.target.value)}>
                    <option>2023</option>
                    <option>2024</option>
                    <option>2025</option>
                </select>
                <Bar options={options} data={data} height="300rem"/>
            </Card>
            <Card className='p-4 d-lg-block d-none'>
                <h4 className='text-center fw-bold'>Order Qty Atayastudio</h4>
                <select className='form-control text-end my-3' onChange={(e)=>setYear(e.target.value)}>
                    <option>2023</option>
                    <option>2024</option>
                    <option>2025</option>
                </select>
                <Bar options={options} data={data} height="100rem"/>
            </Card>
                
                
        </div>
    )
}

export default Analysis