import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import ProductionProgress from './ProductionProgress';
import { useNavigate } from 'react-router-dom';

function Production({setAktif, setStsSidebar, setLoading, name, level, products, orders}) {
    const navigate = useNavigate()
    useEffect(()=>{
        if(name === 'Name') navigate('/auth')
        setAktif("production")
        setStsSidebar("")
    },[setAktif,setStsSidebar])
    const [key, setKey] = useState('progress');
    
    return (
        <div className='content-wrapper'>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="production" title="Production&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" disabled/>
                <Tab eventKey="progress" title="Progress">
                    <ProductionProgress setLoading={setLoading} products={products} orders={orders}/>
                </Tab>
            </Tabs>
        </div>
    )
}

export default Production