import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Card, Dropdown, Form, Modal, Row, Table } from "react-bootstrap"
import ReactSelect from "react-select"
import Swal from "sweetalert2"
import { api_create_manual_order, api_delete_order_by_id, api_delete_order_by_no, api_design_upload, api_get_order_shopee, api_update_due_date, api_update_note, api_update_progress, api_update_shipping, uri } from "../config/api"



function ProductionProgress({setLoading, products, orders}) {
    const underConstruction = ()=> {
        Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: 'This function is under construction!',
            showConfirmButton: false,
            timer: 1500
        })
    }

    useEffect(()=>{
        setLoading(Math.random())
    },[setLoading])

    const [distinctOrder, setDistinctOrder]     = useState([]);
    const [orderDesign, setOrderDesign]         = useState([]);
    const [orderProduction, setOrderProduction] = useState([]);
    const [orderShipping, setOrderShipping]     = useState([]);
    const [orderFinish, setOrderFinish]         = useState([]);
    const [showActive, setShowActive]           = useState("Design");

    const [showing, setShowing] = useState([])

    const handleShow = (status) =>{
        setShowActive(status)
        setShowing(distinctOrder.filter(order => {
            return order.status === status;
        }))
    }

    useEffect(()=>{
        handleShow(showActive)
        const distinct = [];
        const map = new Map();
        for (const item of orders) {
            if(!map.has(item.order_no)){
                map.set(item.order_no, true);
                distinct.push({
                    id          : item.id,
                    u_id        : item.u_id,
                    order_no    : item.order_no,
                    customer    : item.customer,
                    due_date    : item.due_date,
                    status      : item.status,
                    design      : item.design,
                    note        : item.note,
                    resi        : item.resi,
                    service     : item.service,
                    address     : item.address,
                    phone       : item.phone,
                    order_type  : item.order_type,
                    receiver    : item.receiver,
                    order_time  : item.order_time,
                    design_time : item.design_time,
                    prod_time   : item.prod_time,
                    ship_time   : item.ship_time
                });
            }
        }
        setDistinctOrder(distinct)
        
        setOrderDesign(distinctOrder.filter(order => {
            return order.status === "Design";
        }))
        
        setOrderProduction(distinctOrder.filter(order => {
            return order.status === "Production";
        }))

        setOrderShipping(distinctOrder.filter(order => {
            return order.status === "Shipping";
        }))

        setOrderFinish(distinctOrder.filter(order => {
            return order.status === "Finish";
        }))
    },[orders])


    // Add Order Manual -------------------------------------------//
    const [showModalAddOrder, setShowModalAddOrder] = useState(false);
    const [noAdd, setNoAdd]                         = useState("");
    const [custAdd, setCustAdd]                     = useState("");
    const [skuAdd, setSkuAdd]                       = useState("");
    const [qtyAdd, setQtyAdd]                       = useState("");
    const [noteAdd, setNoteAdd]                     = useState("");
    const [statusAdd, setStatusAdd]                 = useState("Design");
    const [dueDateAdd, setDueDateAdd]               = useState("");
    const [optionSku]                               = useState([]);

    const isiSku = () => {
        if(optionSku.length < 1){
            const map = new Map();
            for (const item of products) {
                if(!map.has(item.sku)){
                    map.set(item.sku, true);
                    optionSku.push({
                        value   : item.sku,
                        label   : item.name
                    });
                }
            }
        }
    }    

    const openModalAddOrder = (no_reff, cust, note, sts, due) => {
        isiSku();
        const time  = new Date(Date.now())
        const no    = "M" + time.getDate() + 
                      ("0" + (time.getMonth() + 1)).slice(-2) + 
                      time.getFullYear() + 
                      Math.random().toString(36).substring(2, 7).toLocaleUpperCase()

        if(no_reff !== ""){
            setNoAdd(no_reff)
            setCustAdd(cust)
            setNoteAdd(note)
            setStatusAdd(sts)
            setDueDateAdd(due)
        }else{
            setNoAdd(no)
        }
        setShowModalAddOrder(true)
    }

    const addOrder = async () => {
        console.log(skuAdd)
        await axios.post(api_create_manual_order, {
            updated_by  : "SYSTEM",
            order_no    : noAdd,
            customer    : custAdd,
            sku         : skuAdd,
            qty         : qtyAdd,
            note        : noteAdd,
            status      : statusAdd,
            due_date    : dueDateAdd
        })
        .then(res => {
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            console.log(api_create_manual_order)
            setLoading(Math.random())
            setShowModalAddOrder(false);
        })
    }
    // End of Add Order Manual ====================================// 


    // ------------------ Upload Design ----------------//
    const [showModalUpload, setShowModalUpload] = useState(false)
    const [fileDesign, setFileDesign]           = useState("");
    const [idUpload, setIdUpload]               = useState("");

    const openModalUpload = async(id) =>{
        setIdUpload(id)
        setShowModalUpload(true)
    }

    const uploadDesign  = async () => {
        const formData = new FormData();
        formData.append("order_no", idUpload);
        formData.append("fileDesign", fileDesign);

        await axios.post(api_design_upload, formData, {
            headers: {
              "Content-type": "multipart/form-data",
            }
        })
        .then(res => {
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            console.log(api_design_upload)
            setLoading(Math.random())
            setShowModalUpload(false);
        })
    }
    // ============ End of Upload Design ================// 


    // ---------- Update Shipping Info ----------------//
    const [showModalShipInfo, setShowModalShipInfo] = useState(false);
    const [idShipping, setIdShipping]               = useState("");
    const [resiEdit, setResiEdit]                   = useState("");
    const [serviceEdit, setServiceEdit]             = useState("");
    const [addressEdit, setAddressEdit]             = useState("");
    const [receiverEdit, setReceiverEdit]           = useState("");
    const [phoneEdit, setPhoneEdit]                 = useState("");
    const openModalShipInfo = (id, resi, service, address, receiver, phone) => {
        setIdShipping(id);
        setResiEdit(resi);
        setServiceEdit(service);
        setAddressEdit(address);
        setReceiverEdit(receiver);
        setPhoneEdit(phone);
        setShowModalShipInfo(true);
    }

    const updateShipping  = async () => {
        await axios.post(api_update_shipping, {
            order_no    : idShipping,
            service     : serviceEdit,
            resi        : resiEdit,
            address     : addressEdit,
            receiver    : receiverEdit,
            phone       : phoneEdit
        })
        .then(res => {
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            console.log(api_update_shipping)
            setLoading(Math.random())
            setShowModalShipInfo(false);
        })
    }
    //============ End of Update Shipping Info ================// 

    // ------------------ Update Deadline ----------------//
    const [showModalDeadline, setShowModalDeadline] = useState(false)
    const [idDueDate, setIdDueDate]                 = useState("");
    const [dueDateEdit, setDueDateEdit]             = useState("");
    const openModalDueDate = (id, due_date) => {
        setIdDueDate(id)
        setDueDateEdit(due_date);
        setShowModalDeadline(true);
    }

    const updateDeadline  = async () => {
        await axios.post(api_update_due_date, {
            order_no    : idDueDate,
            due_date    : dueDateEdit
        })
        .then(res => {
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            console.log(api_update_due_date)
            setLoading(Math.random())
            setShowModalDeadline(false);
        })
    }
    //============ End of Update Shipping Info ================// 

    // ------------------ Update Note ----------------//
    const [showModalNote, setShowModalNote] = useState(false)
    const [idNote, setIdNote]                 = useState("");
    const [noteEdit, setNoteEdit]             = useState("");
    const openModalNote = (id, note) => {
        setIdNote(id)
        setNoteEdit(note);
        setShowModalNote(true);
    }

    const updateNote  = async () => {
        const time = Date.now()
        await axios.post(api_update_note, {
            order_no    : idNote,
            note        : noteEdit
        })
        .then(res => {
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            console.log(api_update_note)
            setLoading(time)
            setShowModalNote(false);
        })
    }
    //============ End of Update Note ================// 


    // Search Menu ------------------------------------------------//
    const [searchActive, setSearchActive] = useState(false)
    const handleSearch = () => {
        if(searchActive === true){
            setSearchActive(false)
        }else{
            setSearchActive(true)
        }
    }
    const searchFilter = (e) => {
        setShowing(
            distinctOrder.filter(order => order.order_no.toLowerCase().includes(e.target.value.toLowerCase()))
        )
    }
    // End of Search Menu =========================================// 

    const deleteById = async (id, u_id) => {
        new Swal({
            title               : "Hapus Order",
            text                : u_id,
            icon                : 'warning',
            showCancelButton    : true,
            confirmButtonColor  : '#3085d6',
            cancelButtonColor   : '#d33',
            confirmButtonText   : 'Ya, Hapus!'
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.delete(api_delete_order_by_id + id)
                .then(res => {
                    const parsing = res.data
                    new Swal({
                        title               : parsing.sts,
                        text                : parsing.msg,
                        icon                : parsing.ico,
                        timer               : 2000,
                        showConfirmButton   : false,
                    })
                    console.log(api_delete_order_by_id + id)
                    setLoading(Math.random())
                })
            }
        });
    }

    const deleteByNo = async (no) => {
        new Swal({
            title               : "Hapus Order",
            text                : no,
            icon                : 'warning',
            showCancelButton    : true,
            confirmButtonColor  : '#3085d6',
            cancelButtonColor   : '#d33',
            confirmButtonText   : 'Ya, Hapus!'
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.delete(api_delete_order_by_no + no)
                .then(res => {
                    const parsing = res.data
                    new Swal({
                        title               : parsing.sts,
                        text                : parsing.msg,
                        icon                : parsing.ico,
                        timer               : 2000,
                        showConfirmButton   : false,
                    })
                    console.log(api_delete_order_by_no + no)
                    setLoading(Math.random())
                })
            }
        });
    }

    const updateProgress  = async (no, sts, next) => {
        await axios.post(api_update_progress, {
            no          : no,
            progress    : next
        })
        .then(res => {
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            console.log(api_update_progress)
            setLoading(Math.random())
            handleShow(sts)
        })
    }

    const getOrderShopee  = async() => {
        Swal.fire({
            title               : 'Please Wait',
            allowEscapeKey      : false,
            allowOutsideClick   : false,
            showConfirmButton   : false,
        })
        Swal.showLoading()
        await axios.get(api_get_order_shopee)
        .then(res => {
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            console.log(api_get_order_shopee)
            setTimeout(function() {
                setLoading(Math.random())
            }, 500);
        })
    }

    const [showModalHistory, setShowModalHistory]   = useState(false)
    const [history, setHistory]                     = useState([])

    const openModalHistory = (ot, dd, dsg, dt, pt, st, rs) => {
        setHistory({
            order_time  : ot,
            due_date    : dd,
            design      : dsg,
            design_time : dt,
            prod_time   : pt,
            ship_time   : st,
            resi        : rs
        })
        setShowModalHistory(true)
    }


    
    return (
        <> 
            <Row className="px-2">
                <div className="col-2 p-1">
                    <button className="btn btn-sm btn-outline-info btn-block" onClick={()=>openModalAddOrder("","","","","")}>
                        +
                    </button>
                </div>
                <div className="col-2 p-1">
                    <button className="btn btn-sm btn-outline-info btn-block" onClick={getOrderShopee}> 
                        <i className="bi-arrow-repeat"></i>
                    </button>
                </div>
                <div className="col-7 p-1">
                    <Form.Select size="sm" style={{backgroundColor: "#edf9fc"}} className="border-info text-info" onChange={(e)=>handleShow(e.target.value)} value={showActive}>
                        <option value="Design">Design ( {orderDesign.length} )</option>
                        <option value="Production">Production ( {orderProduction.length} )</option>
                        <option value="Shipping">Shipping ( {orderShipping.length} )</option>
                        <option value="Finish">Finish ( {orderFinish.length} )</option>
                    </Form.Select>
                </div>
                <div className="col-1 p-1 text-center" onClick={handleSearch}>
                    {searchActive === true ? <i className="bi-x-lg"></i> : <i className="bi-search"></i>}
                </div>
            </Row>
            <Row className="px-2 mt-2">
                {searchActive === true ? 
                    <Form.Control type="text" placeholder="Input Order No" onChange={(e)=>searchFilter(e)}/> 
                    : ""
                }
            </Row>
            <div className="row mt-2 p-1">
                {showing && showing.map((order, index) => {
                    let next
                    const due_date_source = new Date(order.due_date * 1)
                    const due_date = due_date_source.toLocaleString("id-ID", { day: "2-digit", month: "short", year: "2-digit" })
                    const deadline = due_date_source.toISOString().split('T')[0]
                    const order_set = orders.filter(orders => {
                        return orders.order_no === order.order_no
                    });
                    return(
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2 p-1" key={index}>
                            <Card className="border-secondary">
                                <Card.Header className={order.due_date > Date.now() ? "px-2" : "bg-danger px-2"}>
                                    <Row>
                                        <div className="col-7 ps-2 pt-0">
                                            <p className="m-0 t9 fw-bold pt-1">{order.customer}</p>
                                        </div>
                                        <div className="col-5 text-end pe-1">
                                            {order.status === "Finish" ? "" :
                                                <>
                                                    <Button className="btn-info px-2 py-0 ms-1" onClick={()=>openModalUpload(order.order_no)}>
                                                        <i className="bi-upload text-white"></i>
                                                    </Button>
                                                    { order.design !== "" && order.design !== null? 
                                                    <Button className="btn-secondary px-2 py-0 ms-1">
                                                        <a href={uri + `design/${order.design}`} target="_self" download>
                                                            <i className="bi-download text-white"></i>
                                                        </a>
                                                    </Button>
                                                    : ""
                                                    }
                                                    {/* <Button className="btn-secondary px-2 py-0 ms-1" onClick={underConstruction}> */}
                                                    <a className="btn btn-secondary px-2 py-0 ms-1" href={`https://api-shopee.ataya.studio/resi/resiauto.php?id=${order.id}`} target="_blank">
                                                        <i className="bi-printer text-white"></i>
                                                    </a>
                                                    {/* </Button> */}
                                                    <div className="btn ps-1 pe-0 py-0 ms-1">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="p-0">
                                                                <i className='fas fa-list-ul text-secondary t9'></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu variant="dark" align="end" className="t10">
                                                                <Dropdown.Item className="mb-2" onClick={()=>openModalShipInfo(order.order_no, order.resi, order.service, order.address, order.receiver, order.phone)}><i className='bi bi-truck me-3'></i>Shipping Info</Dropdown.Item>
                                                                <Dropdown.Item className="mb-2" onClick={()=>openModalDueDate(order.order_no, deadline)}><i className='bi bi-calendar me-3'></i>Deadline Setting</Dropdown.Item>
                                                                <Dropdown.Item className="mb-2" onClick={()=>openModalAddOrder(order.order_no,order.customer,order.note,order.status,deadline)}><i className='bi bi-plus me-3'></i>Add Variant</Dropdown.Item>
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item onClick={()=>deleteByNo(order.order_no)}><i className='bi bi-trash me-3'></i>Delete Order</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="col-7 ps-2 pt-0">
                                            <p className="m-0 t8">{order.order_type} &nbsp;&nbsp;|&nbsp;&nbsp; {order.order_no}</p>
                                        </div>
                                        <div className="col-5 text-end pe-1">
                                            <p className="m-0 t8">{due_date} &nbsp;&nbsp;|&nbsp;&nbsp; {order.status === "Production" ? "Prod" : order.status}</p>
                                        </div>
                                    </Row>
                                </Card.Header>
                                <Card.Body className="p-2">
                                    {order_set && order_set.map((order_product, index)=>{
                                        if(order.status === "Design"){
                                            next = "Production"
                                        }else if(order.status === "Production"){
                                            next = "Shipping"
                                        }else if(order.status === "Shipping"){
                                            next = "Finish"
                                        }else{
                                            next = "Finish"
                                        }

                                        const product_detail = products.filter(product => {
                                            return product.sku === order_product.sku
                                        });
                                        return(
                                            <Row className="mb-2" key={index}>
                                                <div className="col-2">
                                                    {product_detail[0] ? 
                                                        <img alt="sku" src={uri+`product_image/${product_detail[0].image}`} width="65px" height="65px"/>
                                                    : ""
                                                    }
                                                </div>
                                                <div className="col-9">
                                                    {product_detail[0] ? 
                                                        <p className="ms-3 mt-2 mb-0 t9 fw-bold">{product_detail[0].name}</p>
                                                    :   <p className="ms-3 mt-2 mb-0 t9 fw-bold">{order_product.sku}</p>
                                                }
                                                    <p className="ms-3 mb-0 t9">{order_product.qty} pcs</p>
                                                </div>
                                                <div className="col-1 text-end pt-3">
                                                    <i className="bi-trash" role="button" onClick={()=>deleteById(order_product.id, order_product.u_id)}></i>
                                                </div>  
                                            </Row>
                                        )
                                    })}
                                    <Row>
                                        <div className="card bg-secondary t8 text-white px-2 my-2" onClick={()=>openModalNote(order.order_no, order.note)}>{order.note === "" ? "-" : order.note }</div>
                                    </Row>
                                    <Row>
                                        <div className="col-6 t8">{order.service}</div>
                                        <div className="col-6 t8 text-end">
                                            {order.status === "Finish" ? 
                                                <button className="btn btn-info t8 rounded-pill py-0 px-5 text-white" onClick={()=>openModalHistory(order.order_time, order.due_date, order.design, order.design_time, order.prod_time, order.ship_time, order.resi)}>
                                                    Show History
                                                </button>
                                                :
                                                <button className="btn btn-info t8 rounded-pill py-0 px-5 text-white" onClick={()=>updateProgress(order.order_no, order.status, next)}>
                                                    {next}
                                                </button>
                                            }
                                        </div>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    )
                })}      
            </div>

            {/* Modal Scetion */}
            <Modal
                show        = {showModalAddOrder}
                onHide      = {()=>setShowModalAddOrder(false)}
                backdrop    = "static"
                keyboard    = {true}
                size        = "lg"
                centered
                scrollable
            >
                <Modal.Header className="bg-info" closeButton>
                    <Modal.Title className="fw-bold">Add Manual Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4">
                        <Form.Label>Order No</Form.Label>
                        <Form.Control type="text" value={noAdd} readOnly/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Customer</Form.Label>
                        <Form.Control type="text" placeholder="Customer" value={custAdd} onChange={(e)=>setCustAdd(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>SKU</Form.Label>
                        <ReactSelect options={optionSku} onChange={(choice) => setSkuAdd(choice.value)} required/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Qty</Form.Label>
                        <Form.Control type="number" placeholder="Qty" value={qtyAdd} onChange={(e)=>setQtyAdd(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Note</Form.Label>
                        <Form.Control type="text" placeholder="Note" value={noteAdd} onChange={(e)=>setNoteAdd(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Due Date</Form.Label>
                        <Form.Control type="date" value={dueDateAdd} onChange={(e)=>setDueDateAdd(e.target.value)} required/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="p-2">
                    <Button variant="secondary" onClick={()=>setShowModalAddOrder(false)}>
                        Close
                    </Button>
                    <Button variant="info" onClick={addOrder}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show        = {showModalUpload}
                onHide      = {()=>setShowModalUpload(false)}
                backdrop    = "static"
                keyboard    = {true}
                size        = "lg"
                centered
            >
                <Modal.Header className="bg-info" closeButton>
                    <Modal.Title className="fw-bold">Upload Design</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="file" placeholder="Resi" onChange={(e) => setFileDesign(e.target.files[0])}/>
                </Modal.Body>
                <Modal.Footer className="p-2">
                    <Button variant="secondary" onClick={()=>setShowModalUpload(false)}>
                        Close
                    </Button>
                    <Button variant="info" onClick={uploadDesign}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            
            <Modal
                show        = {showModalShipInfo}
                onHide      = {()=>setShowModalShipInfo(false)}
                backdrop    = "static"
                keyboard    = {true}
                size        = "lg"
                centered
            >
                <Modal.Header className="bg-info" closeButton>
                    <Modal.Title className="fw-bold">Edit Shipping Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4">
                        <Form.Label>Resi</Form.Label>
                        <Form.Control type="text" placeholder="Resi" value={resiEdit} onChange={(e)=>setResiEdit(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Service</Form.Label>
                        <Form.Control type="text" placeholder="Service" value={serviceEdit} onChange={(e)=>setServiceEdit(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" placeholder="Address" value={addressEdit} onChange={(e)=>setAddressEdit(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Receiver</Form.Label>
                        <Form.Control type="text" placeholder="Receiver" value={receiverEdit} onChange={(e)=>setReceiverEdit(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text" placeholder="Phone" value={phoneEdit} onChange={(e)=>setPhoneEdit(e.target.value)}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="p-2">
                    <Button variant="secondary" onClick={()=>setShowModalShipInfo(false)}>
                        Close
                    </Button>
                    <Button variant="info" onClick={updateShipping}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show        = {showModalDeadline}
                onHide      = {()=>setShowModalDeadline(false)}
                backdrop    = "static"
                keyboard    = {true}
                size        = "lg"
                centered
            >
                <Modal.Header className="bg-info" closeButton>
                    <Modal.Title className="fw-bold">Edit Deadline</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="date" value={dueDateEdit} onChange={(e)=>setDueDateEdit(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer className="p-2">
                    <Button variant="secondary" onClick={()=>setShowModalDeadline(false)}>
                        Close
                    </Button>
                    <Button variant="info" onClick={updateDeadline}>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show        = {showModalNote}
                onHide      = {()=>setShowModalNote(false)}
                backdrop    = "static"
                keyboard    = {true}
                size        = "lg"
                centered
            >
                <Modal.Header className="bg-info" closeButton>
                    <Modal.Title className="fw-bold">Edit Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea className="form-control" type="text" value={noteEdit} onChange={(e)=>setNoteEdit(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer className="p-2">
                    <Button variant="secondary" onClick={()=>setShowModalNote(false)}>
                        Close
                    </Button>
                    <Button variant="info" onClick={updateNote}>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Modal>
            

            <Modal
                show        = {showModalHistory}
                onHide      = {()=>setShowModalHistory(false)}
                backdrop    = "static"
                keyboard    = {true}
                size        = "lg"
                centered
            >
                <Modal.Header className="bg-info" closeButton>
                    <Modal.Title className="fw-bold">Order History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Table width="100%" className="table-borderless">
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td width="50%">
                                        Order Date
                                    </td>
                                    <td width="70%">:&nbsp;&nbsp;&nbsp;
                                        {
                                            new Date(history.order_time * 1).toLocaleString("id-ID")
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Due Date
                                    </td>
                                    <td>:&nbsp;&nbsp;&nbsp;
                                        {
                                            new Date(history.due_date * 1).toLocaleString("id-ID")
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Design Date
                                    </td>
                                    <td>:&nbsp;&nbsp;&nbsp;
                                        {
                                            new Date(history.design_time * 1).toLocaleString("id-ID")
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Production Date
                                    </td>
                                    <td>:&nbsp;&nbsp;&nbsp;
                                        {
                                            new Date(history.prod_time * 1).toLocaleString("id-ID")
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Shipping Date
                                    </td>
                                    <td>:&nbsp;&nbsp;&nbsp;
                                        {
                                            new Date(history.ship_time * 1).toLocaleString("id-ID")
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Resi
                                    </td>
                                    <td>:&nbsp;&nbsp;&nbsp;
                                        {history.resi}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Design
                                    </td>
                                    <td>:&nbsp;&nbsp;&nbsp;
                                        <a href={uri + "design/" + history.design}>{history.design}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="p-2">
                    <Button variant="secondary" onClick={()=>setShowModalHistory(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ProductionProgress