import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import profilePic from '../assets/img/user.png'

function Header({stsSidebar, setStsSidebar, name, level, setName, setLevel}) {
    
    //Side Bar Control
    const chgStsSidebar = () =>{
        if(stsSidebar === ""){setStsSidebar("active")}else{setStsSidebar("")}
    }
    //End of Sidebar Control

    //Fullscreen Control
    const [fullscreen, setFullscreen] = useState('')
    const elem = document.documentElement;

    const openFullscreen = () => {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
        setFullscreen(1)
    }

    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
        setFullscreen('')
    }

    const navigate = useNavigate();
    const logOut = () => {
        localStorage.setItem('aty_name', '');
        localStorage.setItem('aty_level', '');
        setName('Name')
        setLevel('Level')
        navigate('/auth')
    }


    return (
        <>
            <div className='header mb-4 sticky-top'>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <i className='fas fa-list-ul t12' onClick={chgStsSidebar} role="button"></i>
                        { fullscreen === 1 ? 
                            <i className='fas fa-compress mx-3 t12' onClick={closeFullscreen} role="button"></i>
                            :
                            <i className='fas fa-expand mx-3 t12' onClick={openFullscreen} role="button"></i>
                        }
                        <Link to="/" className='fas fa-home t12 text-body' role="button"></Link>
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle variant="">
                        <div className='d-flex align-items-center' id="dropdown-button-dark-example1">
                            <div>
                                <h6 className='t9 text-end me-2 mb-0'><b>{name}</b></h6>
                                <p className='t7 text-end me-2 mb-0'>{level}</p>
                            </div>
                            <img src={profilePic} className="profile-pic he40 wi40" alt="profile"/>
                        </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark" align="end">
                            <Dropdown.Item><i className='bi bi-person me-3'></i>User Info</Dropdown.Item>
                            <Dropdown.Item><i className='bi bi-gear me-3'></i>Setting</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={logOut}><i className='bi bi-box-arrow-left me-3'></i>Log Out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </>
    )
}

export default Header