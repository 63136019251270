import axios from "axios";
import { useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap"
import Swal from "sweetalert2";
import { api_create_product, api_delete_product, api_update_product, uri } from "../config/api";

function InventoryMaster({setLoading, products}) {
    const [modalTambah, setModalTambah] = useState(false);
    const [cat, setCat]             = useState("");
    const [sku, setSku]             = useState("");
    const [name, setName]           = useState("");
    const [minStock, setMinStock]   = useState("");
    const [orderQty, setOrderQty]   = useState("");
    const [supplier, setSupplier]   = useState("");
    const [file1, setFile1]         = useState("");
  
    const closeTambah   = () => setModalTambah(false);
    const showTambah    = () => setModalTambah(true);
    const createMaster  = async (e) => {
        e.preventDefault();
        setModalTambah(false);

        const formData = new FormData();
        formData.append("cat", cat);
        formData.append("sku", sku);
        formData.append("name", name);
        formData.append("minStock", minStock);
        formData.append("orderQty", orderQty);
        formData.append("supplier", supplier);
        formData.append("file1", file1);

        await axios.post(api_create_product, formData, {
            headers: {
              "Content-type": "multipart/form-data",
            }
        })
        .then(res => {
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            console.log(api_create_product)
            setLoading(Math.random())
        })
    }

    const [modalEdit, setModalEdit] = useState(false);
    const [idEdit, setIdEdit] = useState("");
    const [skuEdit, setSkuEdit] = useState("");
    const [nameEdit, setNameEdit] = useState("");
    const [minStockEdit, setMinStockEdit] = useState("");
    const [orderQtyEdit, setOrderQtyEdit] = useState("");
    const [supplierEdit, setSupplierEdit] = useState("");
    const [file1Edit, setFile1Edit] = useState("");

    const closeEdit     = () => setModalEdit(false);
    const showEdit      = async (id, sku, name, min_stock, order_qty, supplier_url) => {
        setModalEdit    (true)
        setIdEdit       (id)
        setSkuEdit      (sku)
        setNameEdit     (name)
        setMinStockEdit (min_stock)
        setOrderQtyEdit (order_qty)
        setSupplierEdit (supplier_url)
    };

    const updateMaster  = async (e) => {
        e.preventDefault();
        setModalEdit(false);

        const formData = new FormData();
        formData.append("sku", skuEdit);
        formData.append("name", nameEdit);
        formData.append("minStock", minStockEdit);
        formData.append("orderQty", orderQtyEdit);
        formData.append("supplier", supplierEdit);
        formData.append("file1", file1Edit);
        
        await axios.post(api_update_product + idEdit, formData, {
            headers: {
              "Content-type": "multipart/form-data",
            }
        })
        .then(res => {
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            console.log(api_update_product + idEdit)
            setLoading(Math.random())
        })
    }

    const deleteMaster = async (sku) => {
        new Swal({
            title               : sku,
            text                : "Yakin akan menghapus master ini?",
            icon                : 'warning',
            showCancelButton    : true,
            confirmButtonColor  : '#3085d6',
            cancelButtonColor   : '#d33',
            confirmButtonText   : 'Ya, Hapus!'
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.delete(api_delete_product + sku)
                .then(res => {
                    const parsing = res.data
                    new Swal({
                        title               : parsing.sts,
                        text                : parsing.msg,
                        icon                : parsing.ico,
                        timer               : 2000,
                        showConfirmButton   : false,
                    })
                    console.log(api_delete_product + sku)
                    setLoading(Math.random())
                })
            }
        });
    }

    return (
        <>  
            <Row className="px-1">
                <button className="btn btn-outline-info btn-sm mb-2 fw-bold" onClick={showTambah} > + Master</button>
            </Row>
            <Row className="px-1">
                {products && products.map((product, index) => (
                <div className="col-6 col-md-2 p-1" key={product.sku}>
                    <div className="card p-2">
                        <Row>
                            <div className="col-10 t8">
                                <div className="fw-bold mb-2 t8">{product.name}</div>
                            </div>
                            <div className="col-2 t8">
                                <Dropdown>
                                    <Dropdown.Toggle variant="">
                                        <div className='d-flex align-items-center' id="dropdown-button-dark-example1">
                                            <i className='fas fa-list-ul text-secondary t9'></i>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu variant="dark" align="end" className="t7">
                                        <Dropdown.Item onClick={()=>showEdit(product.id, product.sku, product.name, product.min_stock, product.order_qty, product.supplier_url)}><i className='bi bi-pen me-3'></i>Edit</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={()=>deleteMaster(product.sku)}><i className='bi bi-trash me-3'></i>Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="text-center pt-1">
                                <img src={uri + "product_image/" + product.image} alt="led" width="65px" height="65px"/>
                            </div>
                            <div className="col-12 t8">
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td width="50%">SKU</td>
                                            <td width="50%"> : {product.sku}</td>
                                        </tr>
                                        <tr>
                                            <td>Supplier</td>
                                            <td> : <a href={product.supplier_url}>Url</a></td>
                                        </tr>
                                        <tr>
                                            <td>Stock Min</td>
                                            <td> : {product.min_stock} pcs </td>
                                        </tr>
                                        <tr>
                                            <td>Order Qty</td>
                                            <td> : {product.order_qty} pcs </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <div className="col-4 text-end">
                            
                            </div>
                        </Row>
                                    
                        </div>
                    </div>
                ))}
            </Row>

            <Modal show={modalTambah} onHide={closeTambah} centered>
                <Modal.Header className="h5 py-2 bg-info" closeButton>
                    <Modal.Title>Tambah Master</Modal.Title>
                </Modal.Header>
                <Modal.Body className="t9">
                    <Form onSubmit={createMaster}>
                        <Form.Group>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Category</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Select value={cat} onChange={(e)=>setCat(e.target.value)}>
                                        <option value="Tumbler" selected>Tumbler</option>
                                        <option value="Accessories">Accessories</option>
                                        <option value="UV Print">UV Print</option>
                                        <option value="Lainnya">Lainnya</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>SKU</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm" type="text"  value={sku} onChange={(e) => setSku(e.target.value)} placeholder="(jenis-tipe-warna) -> (xx-xxx-xx)" required />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Part Name</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm"  type="text"  value={name} onChange={(e) => setName(e.target.value)} required />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Minimal Stock</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm"  type="number"  value={minStock} onChange={(e) => setMinStock(e.target.value)} required />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Order Qty</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm"  type="number"  value={orderQty} onChange={(e) => setOrderQty(e.target.value)} required />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Supplier Url</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm"  type="text"  value={supplier} onChange={(e) => setSupplier(e.target.value)} required />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Image</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm" type="file" onChange={(e) => setFile1(e.target.files[0])} required/>
                                </Col>
                            </Row>
                        </Form.Group>
                        <div className="modal-footer p-0 pt-2">
                            <Button variant="secondary" onClick={closeTambah}>
                                Close
                            </Button>
                            <Button variant="info" type="submit">
                                Save Changes
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={modalEdit} onHide={closeEdit} centered>
                <Modal.Header className="h5 py-2 bg-info" closeButton>
                    <Modal.Title>Edit Master</Modal.Title>
                </Modal.Header>
                <Modal.Body className="t9">
                    <Form onSubmit={updateMaster}>
                        <Form.Group>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>SKU</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm" type="text"  value={skuEdit} onChange={(e) => setSkuEdit(e.target.value)} required/>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Part Name</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm"  type="text"  value={nameEdit} onChange={(e) => setNameEdit(e.target.value)} required/>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Minimal Stock</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm"  type="number"  value={minStockEdit} onChange={(e) => setMinStockEdit(e.target.value)} required/>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Order Qty</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm"  type="number"  value={orderQtyEdit} onChange={(e) => setOrderQtyEdit(e.target.value)} required/>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Supplier Url</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm"  type="text"  value={supplierEdit} onChange={(e) => setSupplierEdit(e.target.value)} required/>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Image</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm" type="file" onChange={(e) => setFile1Edit(e.target.files[0])} />
                                </Col>
                            </Row>
                        </Form.Group>
                        <div className="modal-footer p-0 pt-2">
                            <Button variant="secondary" onClick={closeEdit}>
                                Close
                            </Button>
                            <Button variant="info" type="submit">
                                Save Changes
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default InventoryMaster