import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { api_create_logistics } from "../config/api";

function InventoryStock({setLoading, products, orders, logistics, ng}) {
    const [modalOrder, setModalOrder] = useState(false);
    const closeModalOrder = () => setModalOrder(false);
    const showModalOrder = () => setModalOrder(true);

    const [skuOrder, setSkuOrder] = useState("");
    const [qtyOrder, setQtyOrder] = useState("");
    const [stdQty, setStdQty] = useState("");
    
    useEffect(() => {
        setLoading(Math.random())
    }, [setLoading])

    const handleModalOrder = (sku, sqty) => {
        setSkuOrder(sku)
        setStdQty(sqty)
        showModalOrder()
    }

    const createOrder = async (e) =>{
        e.preventDefault();
        setModalOrder(false);

        await axios.post(api_create_logistics, {
            sku: skuOrder,
            qty: qtyOrder,
        })
        .then(res => {
            console.log(api_create_logistics)
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            
            setLoading(Math.random())
        })
    }


    return (
        <>  
            <div className="table-responsive px-1">
                <table className="table table-bordered t9">
                    <thead className="text-center">
                        <tr>
                            <th width="40%">Product</th>
                            <th width="20%">Stock</th>
                            <th width="20%">P Stock</th>
                            <th width="20%">Act</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products && products.map((product, index) => {
                            const logisticOrder = logistics.filter(logistic => {
                                return logistic.sku === product.sku && logistic.status !== "Finish"
                            }); 
                            const logisticFinish = logistics.filter(logistic => {
                                return logistic.sku === product.sku && logistic.status === "Finish"
                            }); 
                            const orderIn = orders.filter(order => {
                                return order.sku === product.sku 
                            });
                            const ngProd = ng.filter(ng => {
                                return ng.sku === product.sku 
                            }); 


                            let qty_order   = logisticOrder.reduce(function(prev, current) {
                                return prev + +current.qty
                            }, 0);
                            let qty_finish   = logisticFinish.reduce(function(prev, current) {
                                return prev + +current.qty
                            }, 0);
                            let qty_order_in = orderIn.reduce(function(prev, current) {
                                return prev + +current.qty
                            }, 0);
                            let qty_ng       = ngProd.reduce(function(prev, current) {
                                return prev + +current.qty
                            }, 0);
                            return(
                            <tr className="text-center" key={product.id}>
                                <td className="text-start">{product.name}</td>
                                <td className={qty_finish - qty_order_in - qty_ng < product.min_stock ? "ng" : ""}>{qty_finish - qty_order_in - qty_ng}</td>                
                                <td > {qty_order + qty_finish - qty_order_in - qty_ng} </td>    
                                <td className="p-1">
                                    <button className="btn btn-sm btn-info" onClick={()=> handleModalOrder(product.sku, product.order_qty)}><i className="bi-cart"></i></button>
                                </td>
                            </tr>
                        )})}
                    </tbody>
                </table>
                
                <div className="text-danger t7">
                    <li>Background merah = stock dibawah stock minimum</li>
                    <li>OQ = Standar Order Qty</li>
                </div>
            </div>

            <Modal show={modalOrder} onHide={closeModalOrder} centered>
                <Modal.Header className="h5 py-2 bg-info" closeButton>
                <Modal.Title>Order Part</Modal.Title>
                </Modal.Header>
                <Modal.Body className="t9">
                    <Form onSubmit={createOrder}>
                        <Form.Group>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>SKU</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm" type="text"  value={skuOrder} disabled />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={4} sm={12}>
                                    <Form.Label>Qty Order</Form.Label>
                                </Col>
                                <Col md={8} sm={12}>
                                    <Form.Control size="sm"  type="number"  value={qtyOrder} onChange={(e) => setQtyOrder(e.target.value)}  placeholder="Masukkan Order Qty" required />
                                </Col>
                            </Row>
                        </Form.Group>
                        <p className="fw-bold text-danger">#Standard Order Qty = {stdQty}</p>
                        <div className="modal-footer p-0 pt-2">
                            <Button variant="secondary" onClick={closeModalOrder}>
                                Close
                            </Button>
                            <Button variant="info" type="submit">
                                Save Changes
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default InventoryStock