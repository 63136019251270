import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import InventoryMaster from './InventoryMaster';
import InventoryOrder from './InventoryOrder';
import InventoryStock from './InventoryStock';
import { useNavigate } from 'react-router-dom';

function Inventory({setAktif, setStsSidebar, setLoading, name, level, products, orders, logistics, ng}) {
    const navigate = useNavigate()
    useEffect(()=>{
        if(name === 'Name') navigate('/auth')
        setAktif("inventory")
        setStsSidebar("")
    },[setAktif,setStsSidebar])
    const [key, setKey] = useState('stock');

    return (
        <div className='content-wrapper'>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-1"
            >
                <Tab eventKey="inventory" title="Inventory&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" disabled />
                <Tab eventKey="stock" title="Stock">
                    <InventoryStock setLoading={setLoading} products={products} orders={orders} logistics={logistics} ng={ng}/>
                </Tab>
                <Tab eventKey="order" title="Order">
                    <InventoryOrder setLoading={setLoading} products={products} logistics={logistics} level={level}/>
                </Tab>
                {
                    level === 'Super Admin' ?
                    <Tab eventKey="masterData" title="Master">
                        <InventoryMaster  setLoading={setLoading} products={products}/>
                    </Tab>
                    :""

                }
            </Tabs>
        </div>
    )
}

export default Inventory