import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import pnginven from '../assets/img/inventory.png'
import pngprod from '../assets/img/production.png'
import pngng from '../assets/img/notgood.png'
import pngfile from '../assets/img/fileman.png'

function Dashboard({setAktif, setStsSidebar, setLoading, name, level, products, orders, logistics}) {

    const navigate = useNavigate()

    useEffect(()=>{
        if(name === 'Name') navigate('/auth')
        setLoading(Math.random())
        setAktif("dashboard")
        setStsSidebar("")
    },[setAktif, setLoading, setStsSidebar])

    const [distinctOrder, setDistinctOrder]     = useState([]);

    useEffect(()=>{
        const distinct = [];
        const map = new Map();
        for (const item of orders) {
            if(!map.has(item.order_no)){
                map.set(item.order_no, true);
                distinct.push({
                    id          : item.id,
                    order_no    : item.order_no,
                    customer    : item.customer,
                    due_date    : item.due_date,
                    status      : item.status,
                    design      : item.design,
                    note        : item.note,
                    resi        : item.resi,
                    service     : item.service,
                    address     : item.address,
                    order_type  : item.order_type,
                    receiver    : item.receiver  
                });
            }
        }
        setDistinctOrder(distinct)
    },[orders])

    return (
        <>
            {/* <h5 className='px-3 mb-4'>Dashboard</h5> */}
            
            <div className='mb-4 mt-5'>
                <div className='row text-center'>
                    <Link to="/inventory" className='col-3 p-0'>
                        <img src={pnginven} alt="inventory" width="40px"/>
                        <div className='t9 mt-2 text-dark'>Inventory</div>
                    </Link>
                    <Link to="/production" className='col-3 p-0'>
                        <img src={pngprod} alt="production" width="40px"/>
                        <div className='t9 mt-2 text-dark'>Production</div>
                    </Link>
                    <Link to="/not_good"  className='col-3 p-0'>
                        <img src={pngng} alt="procurement" width="40px"/>
                        <div className='t9 mt-2 text-dark'>Not Good</div>
                    </Link>
                    <Link to="/invoice_quotation"  className='col-3 p-0'>
                        <img src={pngfile} alt="procurement" width="40px"/>
                        <div className='t9 mt-2 text-dark'>Invoice</div>
                    </Link>
                </div>
            </div>
            <div className='row py-2'>
                <div className='col-lg-4 col-md-6 col-sm-12 px-4 py-2 p-2'>
                    <div className='project border-info'>
                        <div className='project-kiri-info fw-bold'>
                            <i className='bi-tools m-0'></i>
                            <div className='t9'>Production</div>
                        </div>
                        <div className='project-kanan-info fw-bold px-2'>
                            <div className='row my-3 d-flex align-item-center'>
                                <div className='col-4 text-center t7'>
                                    <p className='t15 mb-0'>
                                        {distinctOrder.filter(order => {
                                            return order.status === "Design";
                                        }).length}
                                    </p> Design
                                </div>
                                <div className='col-4 text-center t7'>
                                    <p className='t15 mb-0'>
                                        {distinctOrder.filter(order => {
                                            return order.status === "Production";
                                        }).length}
                                    </p> Production
                                </div>
                                <div className='col-4 text-center t7'>
                                    <p className='t15 mb-0'>
                                        {distinctOrder.filter(order => {
                                            return order.status === "Shipping";
                                        }).length}
                                    </p> Shipping
                                </div>                          
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12 px-4 py-2 p-2'>
                    <div className='project border-info'>
                        <div className='project-kiri-info fw-bold'>
                            <i className='bi-box-seam m-0'></i>
                            <div className='t9'>Inventory</div>
                        </div>
                        <div className='project-kanan-info fw-bold px-2'>
                            <div className='row my-3 px-4'>
                                <div className='col-6 text-center t7'>
                                    <p className='t15 mb-0'>0</p> Shortage
                                </div>    
                                <div className='col-6 text-center t7'>
                                    <p className='t15 mb-0'>0</p> Normal
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12 px-4 py-2 p-2'>
                    <div className='project border-info'>
                        <div className='project-kiri-info fw-bold'>
                            <i className='bi-cart m-0'></i>
                            <div className='t9'>Procurement</div>
                        </div>
                        <div className='project-kanan-info fw-bold px-2'>
                            <div className='row my-3 px-4'>
                                <div className='col-6 text-center t7'>
                                    <p className='t15 mb-0'>
                                        {logistics.filter(logistic => {
                                            return logistic.status === "Plan";
                                        }).length}
                                    </p> Plan
                                </div>    
                                <div className='col-6 text-center t7'>
                                    <p className='t15 mb-0'>
                                        {logistics.filter(logistic => {
                                            return logistic.status === "Order";
                                        }).length}
                                    </p> Order
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard