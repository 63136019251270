import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import Swal from "sweetalert2";
import { api_create_ng, api_delete_ng, api_update_ng } from "../config/api";
import { useNavigate } from "react-router-dom";

function NotGood({setAktif, setStsSidebar, setLoading, name, level, products, ng}) {
    const navigate = useNavigate()
    useEffect(()=>{
        if(name === 'Name') navigate('/auth')
        setAktif("not_good")
        setStsSidebar("")
    },[setAktif,setStsSidebar])

    // Add Not Good -----------------------------------------------//
    const [showModalAddNg, setShowModalAddNg]       = useState(false);
    const [NgDate, setNgDate]                       = useState("");
    const [NgType, setNgType]                       = useState("");
    const [NgQty, setNgQty]                         = useState("");
    const [NgRemark, setNgRemark]                   = useState("");
    const [NgSku, setNgSku]                         = useState("");
    const [optionSku]                               = useState([]);

    const isiSku = () => {
        if(optionSku.length < 1){
            const map = new Map();
            for (const item of products) {
                if(!map.has(item.sku)){
                    map.set(item.sku, true);
                    optionSku.push({
                        value   : item.sku,
                        label   : item.name
                    });
                }
            }
        }
    }    

    const createNg = async (e) =>{
        e.preventDefault();

        await axios.post(api_create_ng, {
            date        : NgDate,
            sku         : NgSku,
            qty         : NgQty,
            type        : NgType,
            remark      : NgRemark,
            updated_by  : "SYSTEM",
        })
        .then(res => {
            console.log(api_create_ng)
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            
            setShowModalAddNg(false);
            setLoading(Math.random())
        })
    }

    const openModalAddNg = () => {
        setShowModalAddNg(true)
        isiSku()
    }
    // End of Add Not Good ========================================//

    // Edit Not Good -----------------------------------------------//
    const [showModalEditNg, setShowModalEditNg] = useState(false);
    const [NgIdEdit, setNgIdEdit]               = useState("");
    const [NgDateEdit, setNgDateEdit]           = useState("");
    const [NgTypeEdit, setNgTypeEdit]           = useState("");
    const [NgQtyEdit, setNgQtyEdit]             = useState("");
    const [NgRemarkEdit, setNgRemarkEdit]       = useState("");
    const [NgSkuEdit, setNgSkuEdit]             = useState("");

    const openModalEditNg = (id, date, sku, type, qty, remark) => {
        setShowModalEditNg(true)
        isiSku()
        setNgIdEdit(id)
        setNgDateEdit(date)
        setNgSkuEdit(sku)
        setNgTypeEdit(type)
        setNgQtyEdit(qty)
        setNgRemarkEdit(remark)
    }

    const editNg = async () => {
        await axios.post(api_update_ng + NgIdEdit, {
            date        : NgDateEdit,
            sku         : NgSkuEdit,
            qty         : NgQtyEdit,
            type        : NgTypeEdit,
            remark      : NgRemarkEdit,
            updated_by  : "SYSTEM",
        })
        .then(res => {
            console.log(api_update_ng)
            const parsing = res.data
            new Swal({
                title               : parsing.sts,
                text                : parsing.msg,
                icon                : parsing.ico,
                timer               : 2000,
                showConfirmButton   : false,
            })
            
            setShowModalEditNg(false);
            setLoading(Math.random())
        })
    }

    const deleteNg = async (sku) => {
        new Swal({
            title               : sku,
            text                : "Yakin akan menghapus Ng ini?",
            icon                : 'warning',
            showCancelButton    : true,
            confirmButtonColor  : '#3085d6',
            cancelButtonColor   : '#d33',
            confirmButtonText   : 'Ya, Hapus!'
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.delete(api_delete_ng + sku)
                .then(res => {
                    const parsing = res.data
                    new Swal({
                        title               : parsing.sts,
                        text                : parsing.msg,
                        icon                : parsing.ico,
                        timer               : 2000,
                        showConfirmButton   : false,
                    })
                    console.log(api_delete_ng + sku)
                    setLoading(Math.random())
                    setShowModalEditNg(false)
                })
            }
        });
    }
    // End of Edit Not Good ========================================//

    const xx = () => {

    }

    return (
        <>
            <Row className="px-2">
                <div className="col-2 p-1 fw-bold">
                    NG
                </div>
                <div className="col-2 p-1">
                    <button className="btn btn-sm btn-outline-info btn-block" onClick={openModalAddNg}>
                        +
                    </button>
                </div>
                <div className="col-8 p-1">
                    <Form.Select size="sm" style={{backgroundColor: "#edf9fc"}} className="border-info text-info">
                        <option value="All">All</option>
                        <option value="Supplier">Supplier</option>
                        <option value="Process">Process</option>
                        <option value="Test">Test</option>
                        <option value="Mesin Error">Mesin Error</option>
                    </Form.Select>
                </div>
            </Row>
            <div className="px-1 mt-3">
                <table className="table table-bordered t9">
                    <thead className="text-center">
                        <tr>
                            <th width="20%">Tanggal</th>
                            <th width="35%">SKU & Qty</th>
                            <th width="45%">Tipe & Keterangan</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ng && ng.map((not_good, index)=>{
                            return(

                                <tr onClick={level === "Super Admin" ? ()=>openModalEditNg(not_good.id, not_good.date, not_good.sku, not_good.type, not_good.qty, not_good.remark) : ()=>xx()} key={index}>
                                    <td className="va-middle">{not_good.date}</td>
                                    <td>
                                        <p className="text-danger m-0">{not_good.sku}</p>
                                        <p className="m-0">{not_good.qty} pcs</p>
                                    </td>
                                    <td>
                                        <p className="text-danger m-0">{not_good.type}</p>
                                        <p className="m-0">{not_good.remark}</p>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {/* Modal Section */}
            <Modal
                show        = {showModalAddNg}
                onHide      = {()=>setShowModalAddNg(false)}
                backdrop    = "static"
                keyboard    = {true}
                size        = "lg"
                centered
                scrollable
            >
                <Modal.Header className="bg-info" closeButton>
                    <Modal.Title className="fw-bold">Add NG</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={createNg}>
                        <Form.Group className="mb-4">
                            <Form.Label>Tanggal</Form.Label>
                            <Form.Control type="date" value={NgDate} onChange={(e)=>setNgDate(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label>SKU</Form.Label>
                            <ReactSelect options={optionSku} onChange={(choice) => setNgSku(choice.value)} required/>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label>Qty</Form.Label>
                            <Form.Control type="number" placeholder="Qty" value={NgQty} onChange={(e)=>setNgQty(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label>Tipe</Form.Label>
                            <Form.Select value={NgType} onChange={(e)=>setNgType(e.target.value)} required>
                                <option value="">- Pilih Tipe Ng -</option>
                                <option value="Supplier">Supplier</option>
                                <option value="Process">Process</option>
                                <option value="Test">Test</option>
                                <option value="Mesin Error">Mesin Error</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label>Keterangan</Form.Label>
                            <Form.Control type="text" placeholder="Note" value={NgRemark} onChange={(e)=>setNgRemark(e.target.value)} required/>
                        </Form.Group>
                        <div className="modal-footer p-0 m-0">
                            <Button variant="secondary" onClick={()=>setShowModalAddNg(false)}>
                                Close
                            </Button>
                            <Button variant="info" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                show        = {showModalEditNg}
                onHide      = {()=>setShowModalEditNg(false)}
                backdrop    = "static"
                keyboard    = {true}
                size        = "lg"
                centered
                scrollable
            >
                <Modal.Header className="bg-info" closeButton>
                    <Modal.Title className="fw-bold">Edit NG</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4">
                        <Form.Label>Tanggal</Form.Label>
                        <Form.Control type="date" value={NgDateEdit} onChange={(e)=>setNgDateEdit(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>SKU</Form.Label>
                        <ReactSelect options={optionSku} onChange={(choice) => setNgSkuEdit(choice.value)} defaultInputValue={NgSkuEdit}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Qty</Form.Label>
                        <Form.Control type="number" placeholder="Qty" value={NgQtyEdit} onChange={(e)=>setNgQtyEdit(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Tipe</Form.Label>
                        <Form.Select value={NgTypeEdit} onChange={(e)=>setNgTypeEdit(e.target.value)}>
                            <option value="Supplier">Supplier</option>
                            <option value="Process">Process</option>
                            <option value="Test">Test</option>
                            <option value="Mesin Error">Mesin Error</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Keterangan</Form.Label>
                        <Form.Control type="text" placeholder="Note" value={NgRemarkEdit} onChange={(e)=>setNgRemarkEdit(e.target.value)}/>
                    </Form.Group>
                </Modal.Body>
                <div className="d-flex p-2 justify-content-between">
                    <Button variant="secondary" onClick={()=>setShowModalEditNg(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={()=>deleteNg(NgIdEdit)}>
                        Hapus
                    </Button>
                    <Button variant="info" onClick={editNg}>
                        Submit
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default NotGood