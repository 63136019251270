import axios from "axios";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Absen({setAktif, setStsSidebar, setLoading, name, level}) {
    const navigate = useNavigate()
    useEffect(()=>{
        if(name === 'Name') navigate('/auth')
        setAktif("absen")
        setStsSidebar("")
    },[setAktif,setStsSidebar])

    const [absensi, setAbsensi] = useState([]);
    useEffect(()=>{
        axios.get("https://api-shopee.ataya.studio/absen/fetch.php")
        .then((res)=>{
            setAbsensi(res.data);
        })
    },[])




    return (
        <>
            <Row className="px-2">
                <table className="table table-bordered t9">
                    <thead className="text-center">
                        <tr className="bg-secondary text-white">
                            <th width="30%">Tanggal</th>
                            <th width="26%">Nama</th>
                            <th width="22%">Masuk</th>
                            <th width="22%">Keluar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            absensi?.map((absen, index)=>{
                                return(
                                    <tr className="text-center" key={index}>
                                        <td>{absen.tanggal}</td>
                                        <td>{absen.nama}</td>
                                        <td>{absen.masuk}</td>
                                        <td>{absen.keluar}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Row>
        </>
    )
}

export default Absen